import React, { Component } from 'react';

import { graphql } from 'gatsby';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Layout from '../src/components/Layout/Layout';
import SEO from '../src/helpers/seo';
import './404.scss';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query notFound($locale: String!) {
    allNotFoundMainText(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          text
        }
      }
    }
    allNotFoundMainImage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

class FourOurFor extends Component {
  state = {};

  render() {
    const heimdallData = {
      name: '',
      image: {
        url: this.props.data.allNotFoundMainImage.edges[0].node.localImage.childImageSharp.fluid,
      },
    };

    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale}>
        <SEO
          title="404 Portaventura Ferrariland Caribe Aquatic Park"
          description="Explora todo un mundo hecho de diversión y conoce nuestros asombrosos parques temáticos, los hoteles tematizados más increíbles y la mejor oferta de entretenimiento, relax, gastronomía y shopping que puedas imaginar."
          canonicalLink={`https://www.portaventuraworld.com${this.props.location.pathname}`}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="error-404-container">
            <div className="title-404">
              {this.props.data.allNotFoundMainText.edges[0].node.title}
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: this.props.data.allNotFoundMainText.edges[0].node.text,
              }}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

export default FourOurFor;
